/** @format */

import { cssVars } from '@atoms/GlobalStyles';
import TooltipButton from '@atoms/TooltipButton';
import { ResourceStatus } from '@common/application/enums/ResourceStatus';
import { css } from '@emotion/react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useIntl } from 'react-intl';

interface FallbackIconProps {
  resourceStatus: ResourceStatus;
}

function FallbackIcon(props: FallbackIconProps) {
  const intl = useIntl();
  const getIconConfig = (resourceStatus: ResourceStatus): { icon: IconProp; color: string; title: string; spin?: boolean; beat?: boolean } => {
    switch (resourceStatus) {
      case ResourceStatus.PENDING:
        return {
          title: intl.formatMessage({
            defaultMessage: 'This file is still being scanned for viruses, you can check if it is finished by refreshing the page. For larger files this may take several minutes.',
            description: 'A notification that a file or image is not yet ready to be viewed due to ongoing anti-virus checks',
          }),
          icon: ['fas', 'spinner'],
          color: cssVars.blue,
          spin: true,
        };
      case ResourceStatus.QUARANTINE:
        return {
          title: intl.formatMessage({
            defaultMessage: 'This file has been marked as a potential virus and quarantined',
            description: 'Notification that a potential virus has been identified in the file that was uploaded',
          }),
          icon: ['fad', 'ban-bug'],
          color: cssVars.red,
          beat: true,
        };
      case ResourceStatus.NOT_FOUND:
      default:
        return {
          title: intl.formatMessage({ defaultMessage: 'An error was encountered while uploading this file. Please try again.' }),
          icon: ['fas', 'triangle-exclamation'],
          color: cssVars.red,
          beat: true,
        };
    }
  };
  const fallbackIconConfig = getIconConfig(props.resourceStatus);
  return (
    <TooltipButton tooltip={fallbackIconConfig.title}>
      <FontAwesomeIcon
        {...props}
        beat={fallbackIconConfig.beat}
        icon={fallbackIconConfig.icon}
        css={css`
          color: ${fallbackIconConfig.color};
          --fa-secondary-color: ${cssVars.black};
        `}
        spin={fallbackIconConfig.spin}
        // title={fallbackIconConfig.title}
      />
    </TooltipButton>
  );
}

export default FallbackIcon;
